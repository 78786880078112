import { Id, UserTokens } from '@root/types/common'
import { Tokens, UserProfileData } from '@root/types/profile'
import { setTokens } from '@root/persistance'

export const refreshToken = (refresh_token: UserTokens['refreshToken']) =>
  fetch(`${process.env.REACT_APP_AUTH_URL}/auth/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': '',
    },
    body: JSON.stringify({ refresh_token }),
  })
    .then(res => res.json())
    .catch(e => {
      throw Error(`FA: error while trying to refresh the access token ${e.message}`)
    })

export const fetchUserData = (userUuid: Id, accessToken: UserTokens['accessToken']) =>
  fetch(`${process.env.REACT_APP_MS_URL}/user/${userUuid}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': '',
      Authorization: `Bearer ${accessToken}`,
    },
  })

export const retreiveUserInfo = async (
  userUuid: string,
  userTokens: UserTokens,
): Promise<UserProfileData> => {
  let userInfo
  try {
    let response = await fetchUserData(userUuid, userTokens.accessToken)

    if (response.status == 401) {
      const { access_token, refresh_token } = (await refreshToken(
        userTokens.refreshToken,
      )) as Tokens
      setTokens({ accessToken: access_token, refreshToken: refresh_token })
      response = await fetchUserData(userUuid, access_token)
    }

    userInfo = await response.json()
  } catch (e) {
    throw Error(`FA userInfo: error on retrieving user info ${e.message}`)
  }

  return userInfo
}

export const retrieveUserSize = async (userData: UserProfileData) => {
  let sizeData
  const faceBreadth = userData.userInformation.faceBreadth
  try {
    const sizeResponse = await fetch(`${process.env.REACT_APP_SIZE_ADVISOR_URL}/sizeAdvisor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'X-XSRF-TOKEN': '',
        // Authorization: `Bearer ${userTokens.accessToken}`,
      },
      body: JSON.stringify({
        fitValue: 'NORMAL',
        hingeDistance: parseInt(faceBreadth.toFixed(0)),
      }),
    })
    sizeData = await sizeResponse.json()
  } catch (e) {
    throw Error(`FA getUserSize: error on retrieving size data ${e.message}`)
  }
  return sizeData.data
}

export const retreiveUserPicture = async (
  region: string,
  profileUuid: string,
  userTokens: UserTokens,
): Promise<string> => {
  let data
  try {
    const response = await fetch(
      `${process.env.REACT_APP_PROFILE_URL}/${region}/${profileUuid}/take0.jpg`,
      {
        headers: {
          Authorization: `Bearer ${userTokens.accessToken}`,
        },
      },
    )
    const image = await response.blob()
    data = URL.createObjectURL(image)
  } catch (e) {
    throw Error(`FA userPicture: error on retrieving user picture ${e.message}`)
  }

  return data
}
