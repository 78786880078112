import { getAllSuggestions, getUserSuggestions } from '@root/utils/suggestions'
import { getSuggestedSize, getSuggestedSizeLabels } from '@root/utils/sizeadvisor'
import { getTokens, getUserId } from '@root/persistance'
import { retreiveUserInfo, retreiveUserPicture, retrieveUserSize } from '@root/utils/user'

import { ConfigWithCallbacks } from '@root/types/config'
import ReactDOM from 'react-dom'
import { ConfigWithCallbacks as SizeAdvConfigWithCallbacks } from '@root/types/sizeadvisorconfig'
import { SuggestedFeature } from '@root/types/profile'
import { logger } from './logger'

export const closeApp = (config?: ConfigWithCallbacks | SizeAdvConfigWithCallbacks) => {
  if (!config) {
    throw new Error('Config is not defined')
  }

  // Unmount FA
  const { selector } = config
  const container = document.querySelector(selector)
  if (container) {
    ReactDOM.unmountComponentAtNode(container)
  } else {
    logger.error(`${selector} container not found`)
  }
}

export const getUserData = async (config?: ConfigWithCallbacks): Promise<SuggestedFeature> => {
  const userUuid = getUserId()
  const userTokens = getTokens()
  if (!userUuid) throw Error('FA getUserData: No user data')
  if (!userTokens) throw Error('FA getUserData: No user auth data')

  const userData = await retreiveUserInfo(userUuid, userTokens)

  const suggestions = getUserSuggestions({
    data: userData,
    defaultMood: config?.defaultMood,
    defaultProductType: config?.defaultProductType,
  })

  let sizeData
  if (config?.enableSizeAdvisor) {
    sizeData = await retrieveUserSize(userData)
  }
  const suggestedData = getAllSuggestions(suggestions)

  let profileImageUrl = ''
  try {
    profileImageUrl = await retreiveUserPicture(
      config?.facescanRegion || '',
      userData.profileUuid,
      getTokens(),
    )
  } catch (e) {
    logger.error(e, 'getUserData')
  }

  return {
    ...suggestedData,
    size: getSuggestedSize(sizeData),
    profileImageUrl,
  }
}

export const getSuggestedSizes = async (
  sizes: { [key: string]: number },
  lowerBound: number,
  upperBound: number,
) => {
  const userUuid = getUserId()
  const userTokens = getTokens()
  if (!userUuid) throw Error('FA getUserData: No user data')
  if (!userTokens) throw Error('FA getUserData: No user auth data')

  const userData = await retreiveUserInfo(userUuid, userTokens)
  const sizeData = await retrieveUserSize(userData)

  return getSuggestedSizeLabels({
    sizes,
    optimalHinge: sizeData.optimalHinge,
    lowerBound,
    upperBound,
  })
}
